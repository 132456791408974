<template>
	<div class="approval_speed">
		<ul>
			<li v-for="item in list" :key="item.username">
				<a-avatar :size="40" :src="item.headimg"  />
				<div class="cont">
					<div class="title">
						<div class="name">{{item.username}}</div>
						<div class="status">
							<span v-if="item.status == 0">待审批</span>
							<span v-else-if="item.status == 1">同意</span>
							<span v-else-if="item.status == 2">拒绝</span>
						</div>
						<div v-if="item.status > 0" class="time">{{item.time}}</div>
					</div>
					<div class="desc">{{item.desc}}</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props:{
		list:{
			type:Array,
			default(){
				return [];
			}
		}
	}
}
</script>

<style lang="less" scoped>
.approval_speed{
	ul{
		li{
			display: flex; padding: 15px 0;
			.cont{
				margin-left: 10px;
				flex:1;
				.title{
					display: flex;
					.status{
						flex:1;
						padding-left: 10px;
					}
				}
			}
		}
	}
}
</style>

